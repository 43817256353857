/**
 * v0 by Vercel.
 * @see https://v0.dev/t/p3U72NwFfE8
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import { Button } from "~/components/ui/button";
import { Sheet, SheetTrigger, SheetContent, SheetClose, SheetTitle } from "~/components/ui/sheet";
import { Link } from "react-router";
import { ROUTES } from "~/services/const";
import { CircleUserRound, Menu } from "lucide-react";
import OmniSearch from "./OmniSearch";
import { useState } from "react";
import { useEffect } from "react";

interface LinkProps {
  to: string;
  children: React.ReactNode;
}

function DesktopLink({ to, children }: LinkProps) {
  return (
    <Link to={to} className="font-medium flex items-center text-sm transition-colors hover:underline">
      {children}
    </Link>
  );
}

function DesktopLinks({ isAuthenticated, firstName }: { isAuthenticated: boolean; firstName: string }) {
  return (
    <>
      <OmniSearch label="Pesquisar" />
      <DesktopLink to="/radar">Radar do Teatro</DesktopLink>
      <DesktopLink to="/salas">Salas</DesktopLink>
      {!!isAuthenticated ? (
        <Button asChild size="sm" variant="outline">
          <Link to={ROUTES.DASHBOARD}>
            <CircleUserRound /> {firstName}
          </Link>
        </Button>
      ) : (
        <div className="flex flex-row gap-2">
          <Button size="sm">
            <Link to={ROUTES.SIGNUP}>Criar conta</Link>
          </Button>
          <Button variant="outline" size="sm">
            <Link to={ROUTES.LOGIN}>Iniciar Sessão</Link>
          </Button>
        </div>
      )}
    </>
  );
}

function MobileLink({ to, children }: LinkProps) {
  return (
    <SheetClose asChild>
      <Link to={to} className="font-medium text-center text-xl transition-colors hover:underline">
        {children}
      </Link>
    </SheetClose>
  );
}

function MobileLinks({ isAuthenticated, firstName }: { isAuthenticated: boolean; firstName: string }) {
  return (
    <>
      <div className="mb-4 flex flex-col gap-4">
        <MobileLink to="/">Página Inicial</MobileLink>
        <MobileLink to="/radar">Radar do Teatro</MobileLink>
        <MobileLink to="/salas">Todas as Salas</MobileLink>
        <MobileLink to="/about">Sobre o Teatro.app</MobileLink>
      </div>

      {!!isAuthenticated ? (
        <SheetClose asChild>
          <Button asChild size="sm" variant="outline">
            <Link to={ROUTES.DASHBOARD}>
              <CircleUserRound /> {firstName}
            </Link>
          </Button>
        </SheetClose>
      ) : (
        <>
          <Button>
            <SheetClose asChild>
              <Link to={ROUTES.SIGNUP}>Criar conta</Link>
            </SheetClose>
          </Button>
          <Button variant="outline">
            <SheetClose asChild>
              <Link to={ROUTES.LOGIN}>Iniciar Sessão</Link>
            </SheetClose>
          </Button>
        </>
      )}
    </>
  );
}

interface NavBarProps {
  isAuthenticated: boolean;
  firstName: string;
}

export default function NavBar({ isAuthenticated = false, firstName }: NavBarProps) {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <nav className="fixed inset-x-0 top-0 z-50 bg-white shadow-sm dark:bg-gray-950/90">
      <div className="w-full max-w-[1280px] mx-auto px-3 md:px-5">
        <div className="flex justify-between h-14 items-center">
          <Link to="/" className="flex items-center cursor-pointer">
            <img src="/teatro-app-logo.png" className="h-6 w-[186px]" />
            <span className="sr-only">Teatro App</span>
          </Link>

          <div className="flex items-center gap-4 md:gap-6">
            <nav className="hidden md:flex gap-6">
              <DesktopLinks isAuthenticated={isAuthenticated} firstName={firstName} />
            </nav>
            <div className="md:hidden">
              <OmniSearch label="Pesquisar" iconOnly={true} />
            </div>
            {isMounted && (
              <Sheet>
                <SheetTrigger asChild>
                  <button className="md:hidden">
                    <Menu size={32} />
                    <span className="sr-only">Toggle navigation</span>
                  </button>
                </SheetTrigger>

                <SheetTitle className="sr-only">Menu</SheetTitle>

                <SheetContent
                  side="right"
                  className="flex flex-col items-center justify-center bg-white dark:bg-gray-950 p-8 w-full "
                >
                  <nav className="flex flex-col gap-6 text-2xl">
                    <MobileLinks isAuthenticated={isAuthenticated} firstName={firstName} />
                  </nav>
                </SheetContent>
              </Sheet>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
