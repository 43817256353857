import { useState, useEffect } from "react";
import { Cookie } from "lucide-react";
import { Link } from "react-router";
import { Button } from "~/components/ui/button";
import { ROUTES } from "~/services/const";
import ReactGA from "react-ga4";

const GA_MEASUREMENT_ID = "G-5Y4XXCZY02";

function CookieConsentBanner() {
  const [showBanner, setShowBanner] = useState(false);

  function acceptCookies() {
    localStorage.setItem("cookie-consent", "true");
    setShowBanner(false);
  }

  useEffect(() => {
    const hasConsent = localStorage.getItem("cookie-consent");
    setShowBanner(!hasConsent);

    // Check if the page is in the teatro.app domain
    if (window.location.hostname === "teatro.app") {
      ReactGA.initialize(GA_MEASUREMENT_ID);
    }
  }, []);

  if (!showBanner) {
    return null;
  }

  return (
    <div className="fixed bottom-2 sm:bottom-4 right-2 sm:right-4 ml-2 sm:ml-4 max-w-[900px] flex flex-col md:flex-row items-center gap-5 bg-gray-900 text-white p-4 sm:p-6 shadow-lg rounded border border-black/30 md:rounded-sm z-50">
      <Cookie size={116} className="shrink-0" />
      <div className="text-center md:text-left">
        <h2 className="text-xl font-semibold mb-2">Privacidade e Cookies</h2>
        <p className="text-sm leading-relaxed max-w-[470px] md:max-w-[600px]">
          Este site utiliza cookies para melhorar a sua experiência. Ao prosseguir, aceita o uso dos nossos cookies.
          Para mais informações, consulte a nossa{" "}
          <Link to={ROUTES.PRIVACY_POLICY} className="text-white underline">
            Política de Privacidade
          </Link>
          .
        </p>
      </div>
      <div className="flex flex-col sm:flex-row gap-5 py-5 md:flex-col md:py-0 md:gap-3">
        <Button
          className="min-w-[160px] text-xs font-semibold bg-white text-gray-900 hover:bg-white/90"
          onClick={acceptCookies}
        >
          Aceitar e continuar
        </Button>
        <Button variant="ghost" className="min-w-[160px] text-xs font-semibold text-white hover:text-gray-600" asChild>
          <Link to={ROUTES.PRIVACY_POLICY}>Saber mais</Link>
        </Button>
      </div>
    </div>
  );
}

export default CookieConsentBanner;
