import { Input } from "~/components/ui/input";
import { Button } from "~/components/ui/button";
import { Instagram, Mail } from "lucide-react";
import { Link } from "react-router";
import TeatroVerticalLogo from "../assets/teatro-vertical-logo.png";
import VivaOTeatro from "../assets/viva-o-teatro.png";
import { ROUTES } from "~/services/const";
import { ReactNode } from "react";
import { venuesPath } from "~/services/url-builder";
import { cx } from "class-variance-authority";
export default function Footer() {
  return (
    <footer className="bg-white border-t ">
      <div className="container max-w-[1280px] mx-auto px-6 py-12 bg-white">
        <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 lg:gap-4">
          {/* Column 1: Logo and Contact */}
          <div className="overflow-hidden flex flex-col items-center justify-center">
            <img src={TeatroVerticalLogo} alt="Teatro.app" className="max-w-[140px] w-full" />
          </div>

          {/* Column 2: Links */}
          <div className="flex flex-col gap-2 items-center justify-center">
            <FooterLink to={ROUTES.ABOUT}>Sobre o Teatro.app</FooterLink>
            <FooterLink to={ROUTES.CONTACT}>Contactos</FooterLink>
            <FooterLink to={ROUTES.PRIVACY_POLICY}>Política de Privacidade</FooterLink>
            <FooterLink to={ROUTES.TERMS_AND_CONDITIONS}>Termos e Condições</FooterLink>
          </div>

          {/* Column 3: More Links */}
          <div className="flex flex-col gap-2 items-center justify-center">
            <FooterLink to={ROUTES.SIGNUP}>Criar conta</FooterLink>
            <FooterLink to={ROUTES.ADD_NEW_PLAY} className="text-orange-500">
              Adicionar Peça
            </FooterLink>
            <FooterLink to={ROUTES.RADAR}>Procurar Espetáculos</FooterLink>
            <FooterLink to={venuesPath()}>Ver Todas as Salas</FooterLink>
          </div>

          {/* Column 4: Newsletter Signup */}
          <div className="overflow-hidden flex flex-col items-center justify-center py-6">
            <img
              src={VivaOTeatro}
              alt="Viva o Teatro"
              className="block max-w-[200px] lg:max-w-[180px] w-full rotate-12"
            />
          </div>
        </div>
      </div>
    </footer>
  );
}

function FooterLink({
  to,
  href,
  className,
  children,
}: {
  to?: string;
  href?: string;
  className?: string;
  children: ReactNode;
}): JSX.Element {
  const baseClassName =
    "max-w-[400px] lg:max-w-none w-full text-center text-sm lg:text-left font-semibold text-gray-500 p-2";
  if (to) {
    return (
      <Link to={to} className={cx(baseClassName, className)}>
        {children}
      </Link>
    );
  }

  return (
    <a href={href} target="_blank" className={cx(baseClassName, className)}>
      {children}
    </a>
  );
}
