import React, { useState, useEffect, useRef } from "react";
import { Search, X, LoaderCircle, Building, Theater } from "lucide-react";
import { useNavigate, useFetcher } from "react-router";
import { useDebounce } from "use-debounce";

declare global {
  function plausible(eventName: string, options?: { props?: Record<string, any> }): void;
}

interface Play {
  id: string;
  title: string;
  slug: string;
  slug_id: string;
}

interface Venue {
  id: string;
  name: string;
  city: string;
  slug_id: string;
}

interface SearchResult {
  id: string;
  name?: string;
  title?: string;
  city?: string;
  author?: string;
  company?: string;
  url: string;
}

interface SearchResults {
  plays: SearchResult[];
  venues: SearchResult[];
}

const SEARCH_DELAY = 500;
const RESULTS_EMPTY_STATE: SearchResults = {
  plays: [],
  venues: [],
};
const METRICS_PREFIX = "OmniSearch";

const LoadingResultsBanner = () => (
  <div className="text-center py-6 sm:py-12">
    <LoaderCircle className="animate-spin mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">A carregar...</h3>
    <p className="text-gray-600">Aguarde...</p>
  </div>
);

const LoadingState = () => (
  <div className="text-center py-6 sm:py-12">
    <LoaderCircle className="animate-spin mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">A pesquisar...</h3>
    <p className="text-gray-600">Por favor, aguarde enquanto procuramos os resultados.</p>
  </div>
);

const EmptyState = () => (
  <div className="text-center py-6 sm:py-12">
    <Search className="mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">Começa a escrever para pesquisar</h3>
    <p className="text-gray-600">Pesquisa por nome de peça ou nome da sala.</p>
  </div>
);

const NoResultsState = () => (
  <div className="text-center py-6 sm:py-12">
    <Search className="mx-auto text-gray-400 w-16 h-16 mb-4" />
    <h3 className="text-lg font-semibold text-gray-900 mb-2">Nenhum resultado encontrado</h3>
    <p className="text-gray-600">Tente ajustar a sua pesquisa e tente novamente.</p>
  </div>
);

interface SearchTriggerButtonProps {
  label: string;
  action: () => void;
  iconOnly?: boolean;
}

function SearchTriggerButton({ label, action, iconOnly = false }: SearchTriggerButtonProps) {
  return (
    <button onClick={action} className={`flex items-center`}>
      <Search strokeWidth={3} size={iconOnly ? 24 : 16} className="text-gray-400" />
      {!iconOnly && <span className="ml-1 font-medium text-sm transition-colors hover:underline">{label}</span>}
    </button>
  );
}

interface ResultGroupProps {
  title: string;
  type: "play" | "venue";
  items: SearchResult[];
  icon: React.ComponentType<{ className?: string; strokeWidth?: number; size?: number }>;
  onSelect: (type: "play" | "venue", item: SearchResult) => void;
}

function ResultGroup({ title, type, items, icon: Icon, onSelect }: ResultGroupProps) {
  return (
    <ul className="space-y-2 mb-2">
      {items.map((item, index) => (
        <li key={index} className="">
          <a
            href={item.url}
            className="flex items-center bg-gray-50 hover:bg-orange-100 rounded-sm p-2 pl-3"
            onClick={() => onSelect(type, item)}
          >
            <Icon className="mr-3 text-gray-300 " strokeWidth={2} size={24} />
            <div className="flex flex-col">
              <span className="font-semibold text-gray-600">{item.name || item.title}</span>
              <span className="text-gray-500 text-sm">
                {title} — {item.city || item.author || item.company}
              </span>
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
}

interface SearchModalProps {
  label: string;
  onSelect?: () => void;
  iconOnly?: boolean;
}

export default function SearchModal({ label, onSelect, iconOnly = false }: SearchModalProps) {
  const navigate = useNavigate();
  const fetcher = useFetcher();
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, SEARCH_DELAY);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [results, setResults] = useState<SearchResults>(RESULTS_EMPTY_STATE);
  const [hasSearched, setHasSearched] = useState(false);

  function clearModal() {
    setIsOpen(false);
    setHasSearched(false);
    setSearchTerm("");
    setResults(RESULTS_EMPTY_STATE);
    if (onSelect) {
      onSelect();
    }
  }

  function openModal() {
    setIsOpen(true);
  }

  function handleResultSelect(type: "play" | "venue", item: SearchResult) {
    setIsLoadingResults(true);
    plausible(`${METRICS_PREFIX} Select Result`, {
      props: {
        type,
        id: item.id,
        description: item.name || item.title,
      },
    });
    navigate(item.url);
    clearModal();
  }

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth < 800);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    function handleEscape(event: KeyboardEvent) {
      if (event.key === "Escape") {
        clearModal();
      }
    }

    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setHasSearched(true);
      fetcher.submit({ query: debouncedSearchTerm }, { method: "POST", action: "/api/search" });
    } else {
      setResults(RESULTS_EMPTY_STATE);
      setHasSearched(false);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (fetcher.data) {
      plausible(`${METRICS_PREFIX} Query`, {
        props: {
          query: debouncedSearchTerm,
          numberOfResults: fetcher.data.plays.length + fetcher.data.venues.length,
        },
      });
      setResults(fetcher.data);
    }
  }, [fetcher.data, debouncedSearchTerm]);

  const hasResults = Object.values(results).some((group) => group.length > 0);
  const isLoading = fetcher.state === "submitting";

  return (
    <>
      <SearchTriggerButton label={label} action={openModal} iconOnly={iconOnly} />

      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-1 sm:p-4 ">
          <div
            className={`bg-white rounded overflow-y-auto ${
              isMobile ? "w-full h-full" : "w-full max-w-2xl max-h-[90vh]"
            }`}
          >
            <div className="p-4 sm:p-6 ">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-semibold text-gray-600">Pesquisar</h2>
                <button onClick={clearModal} className="text-gray-500 hover:text-gray-700">
                  <X size={24} />
                </button>
              </div>

              <div className="relative mb-6">
                <input
                  type="text"
                  placeholder="Pesquisar por nome da peça ou sala"
                  className="w-full py-2 pl-10 pr-4 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoFocus
                  disabled={isLoadingResults}
                />
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
              </div>

              {isLoading ? (
                <LoadingState />
              ) : !hasSearched ? (
                <EmptyState />
              ) : !hasResults ? (
                <NoResultsState />
              ) : isLoadingResults ? (
                <LoadingResultsBanner />
              ) : (
                <div>
                  <ResultGroup
                    title="Sala de Espetáculo"
                    type="venue"
                    items={results.venues}
                    icon={Building}
                    onSelect={handleResultSelect}
                  />
                  <ResultGroup
                    title="Peça de Teatro"
                    type="play"
                    items={results.plays}
                    icon={Theater}
                    onSelect={handleResultSelect}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
