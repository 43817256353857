import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigation,
} from "react-router";
import type { LinksFunction, LoaderFunctionArgs } from "react-router";
import * as Sentry from "@sentry/react";
import "./tailwind.css";
import { getAuthFromRequest } from "./services/auth.server";
import NavBar from "./components/shared/navbar";
import Footer from "./components/shared/Footer";
import CookieConsentBanner from "./components/CookieConsentBanner";
import Loading from "./components/Loading";

export const links: LinksFunction = () => [
  { rel: "preconnect", href: "https://fonts.googleapis.com" },
  {
    rel: "preconnect",
    href: "https://fonts.gstatic.com",
    crossOrigin: "anonymous",
  },
  {
    rel: "stylesheet",
    href: "https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap",
  },
];

export async function loader({ request }: LoaderFunctionArgs) {
  const session = await getAuthFromRequest(request);
  const isAuthenticated = !!session;

  return { isAuthenticated, session };
}

export function Layout() {
  const loaderData = useLoaderData<typeof loader>();
  const { isAuthenticated = false, session = null } = loaderData || {};
  const navigation = useNavigation();
  const isLoading = navigation.state === "loading";

  return (
    <html lang="pt" className="h-full bg-white">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest"></link>
        <link rel="preload" href="/loading.gif" as="image" />

        <Meta />
        <Links />

        <script defer data-domain="teatro.app" data-api="/plausible/api/event" src="/plausible/script.js" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.plausible = window.plausible || function() { 
                (window.plausible.q = window.plausible.q || []).push(arguments) 
              }
            `,
          }}
        />
      </head>
      <body className="h-full bg-white">
        <div className="min-h-screen flex flex-col">
          <NavBar isAuthenticated={isAuthenticated} firstName={session?.firstName || ""} />
          <main className="flex-1 pt-14 overflow-hidden flex flex-col">
            <Outlet />
          </main>
          <CookieConsentBanner />
          <Footer />
        </div>
        {!!isLoading && <Loading />}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return <Outlet />;
}

interface ErrorBoundaryProps {
  error: Error | unknown;
}

export function ErrorBoundary({ error }: ErrorBoundaryProps) {
  let message = "Oops!";
  let details = "An unexpected error occurred.";
  let stack: string | undefined;

  if (isRouteErrorResponse(error)) {
    message = error.status === 404 ? "404" : "Error";
    details = error.status === 404 ? "The requested page could not be found." : error.statusText || details;
  } else if (error && error instanceof Error) {
    // you only want to capture non 404-errors that reach the boundary
    Sentry.captureException(error);
    if (import.meta.env.DEV) {
      details = error.message;
      stack = error.stack;
    }
  }

  return (
    <main>
      <h1>{message}</h1>
      <p>{details}</p>
      {stack && (
        <pre>
          <code>{stack}</code>
        </pre>
      )}
    </main>
  );
}
